import styled from 'styled-components'
import Job from './Job'

const Container= styled.section`
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;

  .content {
    width: 100%;
  }

  @media(max-width: 1024px){
    min-height: auto;
    margin-bottom: 6.4rem;
  }
`

export default function Experience(props){
  return(
    <Container>
      <div className="content">
        {props.content.map((elm, index) =>
          <Job
            content={elm}
            key={index}
          />
        )}
      </div>
    </Container>
  )
}