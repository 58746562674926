import React, { useEffect, useState, useRef } from 'react'
import sanityClient from '../client.js'
import BlockContent from '@sanity/block-content-to-react'
import { useContextInfo } from '../hooks/context'
import Paragraph from '../components/Paragraph'
import Lead from '../components/Lead'
import Experience from '../components/Experience'
import AnimatioBlock from '../components/AnimationBlock'
import Loading from '../components/Loading'
import Fade from 'react-reveal/Fade'
import SectionMarker from '../components/SectionMarker'

export default function About() {
  const [about, setAbout] = useState(null)

  const { setSideContent } = useContextInfo()

  const introRef = useRef(null)
  const experienceRef = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)

    setTimeout(() => {      
      sanityClient
        .fetch(
          `*[_type == "about"][0]{
            intro,
            philosophy,
            experience[]->{
              company,
              role,
              time
            }
          }`
        )
        .then((data) => setAbout(data))
        .catch(console.error)
    }, 1200);
  }, [])

  useEffect(() => {
    if(introRef.current) {
      let result = {
        intro: {
          number: "About",
          top: 0,
          bottom: introRef.current.getBoundingClientRect().bottom,
          text: 'Intro'
        },
        details: {
          number: "About",
          top: experienceRef.current.getBoundingClientRect().top,
          bottom: experienceRef.current.getBoundingClientRect().bottom,
          text: 'Experience'
        }
      }

      setSideContent(result)
    }

    return () => {
      let result = {
        intro: {
          number: '🤖',
          top: 0,
          bottom: window.innerHeight,
          text: 'Loading...'
        }
      }

      setSideContent(result)
    }
  }, [about, setSideContent])

  return (
    <>
      {about ?
      <>
      <div ref={introRef}>
        <Fade>
          <Paragraph>
            <BlockContent
              blocks={about.intro}
              projectId={sanityClient.clientConfig.projectId}
              dataset={sanityClient.clientConfig.dataset}
            />
          </Paragraph>
          <AnimatioBlock/>
          <Lead
            content={about.philosophy}
          />
        </Fade>
      </div>

      <SectionMarker
        label="About"
        section="Experience"
      />

      <div ref={experienceRef}>
        <Fade>
          <Experience
            content={about.experience}
          />
        </Fade>
      </div>
      </>
      :
      <Loading/>
      }
    </>
  )
}