import styled from 'styled-components'

const Content = styled.article`
  grid-column-start: content-start;
  -ms-grid-column: content-start;
  grid-column: content-start;
  -ms-grid-column-span: NaN;
  grid-column-end: side-right;

  @media(max-width: 1024px){
    -ms-grid-column: content-start;
        grid-column-start: content-start;
    -ms-grid-column-span: NaN;
    grid-column-end: content-end;
  }
`

export default Content