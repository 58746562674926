import styled from 'styled-components'
import sanityClient from '../client.js'
import BlockContent from '@sanity/block-content-to-react'

const Container = styled.section`
 height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
          justify-content: flex-end;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  font-size: 3.2rem;
  color: #FFF;
  
  .content {
    width: 70%;
    padding: 2.4rem 3.2rem;
    background-color: #333;
    -webkit-box-shadow: 15px 20px rgba(0, 0, 0, 0.15);
            box-shadow: 15px 20px rgba(0, 0, 0, 0.15);
  }

  @media(max-width: 1024px){
    height: auto;
    margin: 12.8rem 0;
    font-size: 2.4rem;

    .content {
      width: 90%;
    }
  }

  @media(max-width: 440px){
    font-size: 1.8rem;
  }
`

export default function Lead(props) {
  return (
    <Container>
      <div className="content">
        <BlockContent
          blocks={props.content}
          projectId={sanityClient.clientConfig.projectId}
          dataset={sanityClient.clientConfig.dataset}
        />
      </div>
    </Container>
  )
}