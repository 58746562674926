// @flow
import React from 'react';
import styled from 'styled-components';

const StickyStyled = styled.section`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  margin: 3.2rem 0;

  & img {
    position: absolute;
    -o-object-fit: cover;
       object-fit: cover;
  }

  & .image-1 {
    top: 40%;
    left: 15%;
    width: 30%;
    z-index: 5;
  }

  & .image-2 {
    top: 15%;
    right: 15%;
    width: 30%
  }

  & .image-3 {
    bottom: 5%;
    left: 22%;
    width: 20%
  }

  & .image-4 {
    bottom: 10%;
    right: 20%;
    width: 30%
  }

  & .image-5 {
    width: 50%;
  }

  @media(max-width: 1024px){
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: distribute;
        justify-content: space-around;

    & img {
      position: static;
      height:300px;
      padding: 16px;
    }

    & .image-1 {
    width: 45%;
    }

    & .image-2 {
      width: 45%;
    }

    & .image-3 {
      width: 45%;
    }

    & .image-4 {
      width: 45%;
    }

    & .image-5 {
      width: 80%;
    }
  }

  @media(max-width: 440px){
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;

    & img {
      position: static;
      height:300px;
      padding: 16px 0;
    }

    & .image-1 {
      width: 100%;
    }

    & .image-2 {
      width: 100%;
    }

    & .image-3 {
      width: 100%;
    }

    & .image-4 {
      width: 100%;
    }

    & .image-5 {
      width: 100%;
    }
  }
`;

const Showreel = (props) => (
  <StickyStyled>
    <img className="image-1" src={props.image} alt=""/>
    <img className="image-2" src={props.image2} alt=""/>
    <img className="image-3" src={props.image3} alt=""/>
    <img className="image-4" src={props.image4} alt=""/>
    <img className="image-5" src={props.image5} alt=""/>
  </StickyStyled>
);

export default Showreel;