import React, { useState, useLayoutEffect, useEffect } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Project from './pages/Project'
import Layout from './components/Layout'
import Aside from './components/Aside'
import Content from './components/Content'
import Header from './components/Header'
import Footer from './components/Footer'
import Loading from './components/LandingAnimation'
import { useContextInfo } from './hooks/context'
import UnderConstruction from './components/UnderConstruction'

function App() {
  const [scrollPosition, setPosition] = useState(0)
  const [asideLabel, setAsideLabel] = useState(null)
  const [asideSection, setAsideSection] = useState(null)
  const [isDone, setIsDone] = useState(false)

  const { asideContent } = useContextInfo()

  useEffect(() => {
    setTimeout(() => {
      setIsDone(true)
    }, 6000);
  }, [])

  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener('scroll', updatePosition);
  }, []);

  useEffect(()=>{
    if(asideContent()){
      for (const property in asideContent()){
        if(scrollPosition >= asideContent()[property].top && scrollPosition < asideContent()[property].bottom){
          setAsideLabel(asideContent()[property].number)
          setAsideSection(asideContent()[property].text)
        }
      }
    }
  }, [scrollPosition, asideContent, asideLabel, asideSection])

  return (
    <BrowserRouter>
      {isDone ?
      <Layout>
        <Header/>
        <Aside label={asideLabel} section={asideSection}/>
        <Content>
          <Route 
            component={Home} 
            path='/' 
            exact
          />
          <Route
            component={About} 
            path='/about' 
            exact
          />
          <Route
            component={Project} 
            path='/project/:slug'
            exact
          />
        </Content>
        <Footer/>
        <UnderConstruction/>
      </Layout>
      :
      <Loading/>
      }
    </BrowserRouter>
  );
}

export default App;
