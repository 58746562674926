import styled from 'styled-components'
import Project from './Project'

const Container = styled.section`
  min-height: 100vh;

  @media(max-width: 1024px){
    min-height: auto;
  }
`

export default function Projects (props) {
  return (
    <Container>
      {props.content.map((elm, index) =>
        <Project
          slug={elm.slug.current}
          key={index}
          index={index}
          company={elm.company}
          image={elm.previewImage.asset.url}
          role={elm.role}
        />
      )}
    </Container>
  )
}