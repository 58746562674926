import styled from 'styled-components'

const Container= styled.section`
 display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  padding: 3.2rem 0;
  color: #333333;
  border-bottom: solid .1rem #C4C4C4;

  p {
    display: inline-block;
  }

  .top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;

    p {
      font-size: 2.4rem;
    }

    .time {
      font-weight: 300;
      color: #A0A0A0;
    }
  }

  .company {
    font-size: 1.8rem;
    font-weight: 300;
    color: #A0A0A0;
  }

  @media(max-width: 440px){
    .top {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;

      p {
        font-size: 1.8rem;
      }

      .time {
        font-size: 1.4rem
      }
    }

    .company {
      font-size: 1.2rem;
    }
  }
`

export default function Job(props){
  return(
    <Container>
      <div className="top">
        <p className="role">{props.content.role}</p>
        <p className="time">{props.content.time}</p>
      </div>
      <p className="company">{props.content.company}</p>
    </Container>
  )
}