import { useCallback, createContext, useContext, useState } from 'react'

export const AppContext = createContext()

export const AppCtxProvider = (props) => {
  const [aside, setAside] = useState()

  const setSideContent = useCallback((obj) => {
    setAside(obj)
  }, [])

  const asideContent = () => {
    return aside
  }

  const value = { asideContent, setSideContent }

  return <AppContext.Provider {...props} value={value}/>
}

export const useContextInfo = () => useContext(AppContext)