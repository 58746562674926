import styled from 'styled-components'
import bkg from '../images/under-construction.svg'

const Container = styled.div`
  -ms-grid-column: side-left;
  grid-column: side-left;
  grid-column-start: side-left;
  -ms-grid-column-span: NaN;
  grid-column-end: side-right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;

  .content {
    background-image: url(${bkg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 18px;
    margin-top: 2.4rem;
    font-family: 'Teko', sans-serif;
    font-size: 1.4rem;
    color: #333333;
    font-weight: 300;
    text-align: center;

    .title {
      font-weight: 500;
    }
  }

  @media(max-width: 1024px){
    -ms-grid-column: content-start;
        grid-column-start: content-start;
    -ms-grid-column-span: NaN;
    grid-column-end: content-end;
  }
`

export default function UnderConstruction() {
  return (
    <Container>
      <div className='content'>
        <p className='title'>⚒ Permanently under construction ⚒</p>
        <p>Curent stack: Sanity & React</p>
      </div>
    </Container>
  )
}