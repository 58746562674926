import styled from 'styled-components'
import tipo from '../images/tipo.svg'

const Container = styled.section`
 height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;

  .content {
    width: 200px;
    height: 150px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;

    img {
      position: absolute;
      top: 50%;
      right: 50%;
      -webkit-transform: translate(50%, -50%);
          -ms-transform: translate(50%, -50%);
              transform: translate(50%, -50%);
      width: 100px;
      height: 100px;
      z-index: 5;
    }
  }

  .block {
    width: 0;
    height: 80px;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    -webkit-animation-name: change-color;
            animation-name: change-color;
    background-color: black;
    background-color: #01FF83;
  }

  @-webkit-keyframes change-color {
    from{
      width: 0;
    }

    to{
      width: 100%;
    }
  }

  @keyframes change-color {
    from{
      width: 0;
    }

    to{
      width: 100%;
    }
  }

  @media(max-width: 440px){
    .content {
      width: 100px;
      height: 100px;

      img {
        width: 50px;
        height: 50px;
      }
    }

    .block {
      height: 40px;
    }
  }
`

export default function Loading(){
  return (
    <Container>
      <div className="content">
        <img src={tipo} alt=""/>
        <div className="block"></div>
      </div>
    </Container>
  )
}