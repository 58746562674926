import styled from 'styled-components'
import behance from '../images/behance.svg'
import github from '../images/github.svg'
import linkedIn from '../images/linkedIn.svg'

const Container = styled.footer`
  -ms-grid-column: side-left;
  grid-column: side-left;
  grid-column-start: side-left;
  -ms-grid-column-span: NaN;
  grid-column-end: side-right;
  text-align: center;
  margin-top: 12.4rem;
  font-family: 'Teko', sans-serif;
  font-size: 3.6rem;
  color: #333333;

  .social-media {
    margin: 3.2rem 0;

    a {
      margin: 0 3.2rem;
    }
  }

  p:nth-child(2) {
    font-size: 3.6rem;
    font-weight: 300;
  }

  p:last-child {
    font-size: 1.6rem;
    font-weight: 300;
  }

  @media(max-width: 1024px){
    -ms-grid-column: content-start;
    grid-column-start: content-start;
    -ms-grid-column-span: NaN;
    grid-column-end: content-end;
    margin-top: 3.2rem;
    font-size: 2.4rem;

    p:nth-child(2) {
      font-size: 2.4rem;
      font-weight: 300;
    }

    p:last-child {
      font-size: 1.6rem;
      font-weight: 300;
    }
  }
`

export default function Footer() {
  return (
    <Container>
      <p>For more about my work, how to reach me or say Hi</p>
      <p>Here are some useful links</p>
      <div className="social-media">
        <a href="https://linkedin.com/in/aherna-av" target="_blank" rel="noreferrer"><img src={linkedIn} alt=""/></a>
        <a href="https://www.behance.net/ahernav" target="_blank" rel="noreferrer"><img src={behance} alt=""/></a>
        <a href="https://github.com/Ahernandez-av" target="_blank" rel="noreferrer"><img src={github} alt=""/></a>
      </div>
      <p>© 2021 Alberto Hernandez</p>
    </Container>
  )
}