import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useContextInfo } from '../hooks/context'
import sanityClient from '../client.js'
import BlockContent from '@sanity/block-content-to-react'
import Paragraph from '../components/Paragraph'
import Showreel from '../components/Showreel'
import Loading from '../components/Loading'
import Projects from '../components/Projects'
import Fade from 'react-reveal/Fade'
import SectionMarker from '../components/SectionMarker'

export default function Home() {
  const [home, setHome] = useState(null)

  const { setSideContent } = useContextInfo()

  const introRef = useRef(null)
  const projectsRef = useRef(null)
  const aboutRef = useRef(null)
  const showreelRef = useRef(null)
  
  useEffect(() => {
    window.scrollTo(0, 0)
    
    setTimeout(() =>{
      sanityClient
        .fetch(
          `*[_type == "home"][0]{
            about,
            intro,
            projects[]->{
              slug,
              company,
              role,
              previewImage{
              asset->{
              _id,
              url
            }
            }
            },
            showcase{
              image1{
                asset->{
                  _id,
                  url
                }
              },
              image2{
                asset->{
                  _id,
                  url
                }
              },
              image3{
                asset->{
                  _id,
                  url
                }
              },
              image4{
                asset->{
                  _id,
                  url
                }
              },
              image5{
                asset->{
                  _id,
                  url
                }
              },
            }
          }`
        )
        .then((data) => setHome(data))
        .catch(console.error)
    }, 1200)
  }, [])

  useEffect(() => {
    if(projectsRef.current) {
      let result = {
        intro: {
          number: '01',
          top: 0,
          bottom: introRef.current.getBoundingClientRect().bottom,
          text: 'Intro'
        },
        projects: {
          number: '02',
          top: projectsRef.current.getBoundingClientRect().top,
          bottom: projectsRef.current.getBoundingClientRect().bottom,
          text: 'Projects'
        },
        about: {
          number: '03',
          top: aboutRef.current.getBoundingClientRect().top,
          bottom: aboutRef.current.getBoundingClientRect().bottom,
          text: 'about'
        },
        showreel: {
          number: '04',
          top: showreelRef.current.getBoundingClientRect().top,
          bottom: showreelRef.current.getBoundingClientRect().bottom,
          text: 'Showreel'
        }
      }

      setSideContent(result)
    }

    //Error trigerred by this return - issue updating context
    return () => {
      let result = {
        intro: {
          number: '🤖',
          top: 0,
          bottom: window.innerHeight,
          text: 'Loading...'
        }
      }

      setSideContent(result)
    }
  }, [home, setSideContent])

  return (
    <>
      {home ? 
      <>
        <Fade>
          <Paragraph ref={introRef}>
            <BlockContent
              blocks={home.intro}
              projectId={sanityClient.clientConfig.projectId}
              dataset={sanityClient.clientConfig.dataset}
            />
          </Paragraph>
        </Fade>

        <SectionMarker
          label="02"
          section="Projects"
        />

        <section ref={projectsRef}>
        <Fade>
          <Projects
            content ={home.projects}
          />
        </Fade>
        </section>

        <SectionMarker
          label="03"
          section="About"
        />

        <Fade>
          <Paragraph ref={aboutRef}>
            <BlockContent
              blocks={home.about}
              projectId={sanityClient.clientConfig.projectId}
              dataset={sanityClient.clientConfig.dataset}
            />
            <div>
              <Link to='/about'>
                Read More
              </Link>
            </div>
          </Paragraph>
        </Fade>

        <SectionMarker
          label="04"
          section="Showreel"
        />

        <section ref={showreelRef}>
        <Fade>
          <Showreel 
            image={home.showcase.image1.asset.url}
            image2={home.showcase.image2.asset.url}
            image3={home.showcase.image3.asset.url}
            image4={home.showcase.image4.asset.url}
            image5={home.showcase.image5.asset.url}
          />
        </Fade>
        </section>
      </>
      :
      <Loading/>
      }
    </>
  )
}