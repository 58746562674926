import styled from 'styled-components'

const Body = styled.section`
  margin: 3.2rem 0;
  min-height: 100vh;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  font-size: 2.4rem;
  margin-right: 15%;
  color: #333333;

  img {
    width: 100%;
  }

  p {
    padding: 6.4rem 0;
  }

  a {
      color: #34E49E;

      &:visited {
        color: #34E49E;
      }
    }

  @media(max-width: 1024px){
    margin-bottom: 6.4rem;

    p {
      padding: 3.2rem 0;
    }
  }

  @media(max-width: 440px){
    margin-right: 0;

    p {
      font-size: 1.8rem;
    }
  }
`

export default Body