import styled from 'styled-components'
import { Link } from 'react-router-dom'
import webLogo from '../images/web-logo.svg'
import Fade from 'react-reveal/Fade';

const Container = styled.header`
  -ms-grid-column: side-left;
  grid-column: side-left;
  grid-column-start: side-left;
  -ms-grid-column-span: NaN;
  grid-column-end: side-right;
  margin-bottom: 1.6rem;

  img {
    width: 100px;
  }

  @media(max-width: 600px){
    -ms-grid-column: content-start;
        grid-column-start: content-start;
    -ms-grid-column-span: NaN;
    grid-column-end: content-end;
  }
`

export default function Header() {
  return (
    <Fade>
      <Container>
        <Link to='/'>
          <img src={webLogo} alt=''/>
        </Link>
      </Container>
    </Fade>
  )
}