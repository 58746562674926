import styled from 'styled-components'

const Container= styled.section`
  height: 50vh;
  font-family: 'Teko', sans-serif;
  font-size: 3.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  color: #333;

  @media(max-width: 1024px){
    margin: 6.4rem 0;
    font-size: 2.4rem;
    height: auto;
  }

  @media(max-width: 440px){
    font-size: 1.8rem;
  }
`

export default function AnimatioBlock(){
  return(
    <Container>
      <div className="content">
        <p>💻 PROGRAMING + 🖍️ DESIGN = 🛸 FREEDOM</p>
      </div>
    </Container>
  )
}