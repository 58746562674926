import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Container = styled.section`
 font-family: 'Teko', sans-serif;
  text-align: center;
  margin-right: 15%;
  margin-top: 3.2rem;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;

  .content {
    background-color: #000;
    width: 20rem;
    padding: .8rem 1.6rem;

    a {
      font-size: 2.4rem;
      color: #FFF;
    }

    h4 {
      font-size: 3.2rem;
    }

    p {
      font-weight: 300;
      font-size: 1.6rem;
    }
  }

  @media(max-width: 1024px){
    margin-top: 6.4rem;
    margin-right: 0;
  }
`

export default function Back(){
  return (
    <Link to="/">
      <Container>
        <div className="content">
          <h4>GO BACK</h4>
          <p>Thanks for your time! 😁</p>
        </div>
      </Container>
    </Link>
  )
}