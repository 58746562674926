import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Article = styled.article`
display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  height: 17.6rem;
  width: 100%;
  margin: 2.4rem 0;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  color: #333333;

  .project__container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 2.4rem 1.6rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      background-color: #333333;

      p {
        color: #FFF;
      }

      .more {
        color: #01FF83;
      }

      img, .more {
        display: block;
        opacity: 1;
      }
    }

    .headline {
      font-size: 3.6rem;
    }

    .more {
      font-size: 1.4rem;
      display: none;
      opacity: 0;
    }

    .role {
      font-size: 1.8rem;
      color: #A0A0A0;
    }
  }

  img {
    display: none;
    opacity: 0;
    position: absolute;
    z-index: 5;
    width: 34.0rem;
    height: 17.6rem;
    -o-object-fit: cover;
       object-fit: cover;
    right: 15.0rem;
  }

  @media(max-width: 1024px){
    margin: 2.4rem 0;
    height: auto;

    .project__container{
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
      padding: 2.4rem 1.6rem;

      &:hover {
        background-color: transparent;
        pointer-events: none;

        p {
          color: inherit;
        }

        .more {
          color:  #34E49E;
        }

        img {
          display: none;
        }
      }

      .headline {
        font-size: 3.2rem;
      }

      .more {
        font-size: 1.4rem;
        display: block;
        opacity: 1;
        color: #34E49E;
      }

      .role {
        font-size: 1.8rem;
        color: #A0A0A0;
      }
    }

    img {
      display: none;
    }
  }

  @media(max-width: 440px){
    .project__container{
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
      padding: 2.4rem 0;

      &:hover {
        pointer-events: none;
      }

      .headline {
        font-size: 2.0rem;
        color: #000;
      }

      .more {
        font-size: 1.4rem;
        display: block;
        opacity: 1;
        color: #01FF83;
      }

      .role {
        font-size: 1.2rem;
        color: #A0A0A0;
      }
    }
  }
  `

export default function Project (props) {
  return (
  <Link to={`/project/${props.slug}`} key={props.slug}>
    <Article>
      <div className='project__container'>
        <div>          
          <p className='headline'>{props.company}</p>
          <p className='more'>See Project</p>
        </div>
        <p className='role'>{props.role}</p>
        <img src={props.image} alt=''/>
      </div>
    </Article>
  </Link>
  )
}