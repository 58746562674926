import styled from 'styled-components'
import fullTipo from '../images/full-tipo.svg'

const Container = styled.div`
 height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  position: relative;

  .bkg {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: #01FF83;
    z-index: 5;
    -webkit-animation-name: bkg-animation;
            animation-name: bkg-animation;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
  }

  .content {
    position: relative;
    width: 180px;
    -webkit-animation-name: hide-animation;
            animation-name: hide-animation;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;

    img {
      width: 200px;
      position: absolute;
      top: -20px;
      left: 10px;
    }

    .block {
      width: 0;
      height: 20px;
      background-color: #01FF83;
      -webkit-animation-name: block-animation;
              animation-name: block-animation;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      -webkit-animation-delay: 1s;
              animation-delay: 1s;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
    }
  }

  @-webkit-keyframes block-animation {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes block-animation {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  @-webkit-keyframes hide-animation {
    0% {
      opacity: 100%;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes hide-animation {
    0% {
      opacity: 100%;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes bkg-animation {
    0% {
      height: 0;
    }
    50% {
      height: 100%;
      width: 100%;
    }
    100% {
      height: 100%;
      width: 5px;
    }
  }

  @keyframes bkg-animation {
    0% {
      height: 0;
    }
    50% {
      height: 100%;
      width: 100%;
    }
    100% {
      height: 100%;
      width: 5px;
    }
  }
`

export default function Loading() {
  return (
    <Container>
      <div className="bkg"></div>
      <div className="content">
        <img src={fullTipo} alt=""/>
        <div className="block"></div>
      </div>
    </Container>
  )
}