import styled from 'styled-components'

const Container = styled.div`
 display: none;
 font-family: 'Teko', sans-serif;
 color: #333333;

 @media(max-width: 1024px){
    display: block;
    margin: 6.4rem 0;

    .content {
      
      .label {
        font-size: 1.8rem;
        font-weight: 500;
      }

      .section {
        font-size: 1.4rem;
        font-weight: 300;
      }
    }
  }
`

export default function SectionMarker(props) {
  return (
    <Container>
      <div className="content">
        <p className="label">{props.label}</p>
        <p className="section">{props.section}</p>
      </div>
    </Container>
  )
}