import styled from 'styled-components'

const Container = styled.section`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Teko', sans-serif;
  height: 30vh;
  color: #333333;

  div {
    margin-right: .8rem;
    width: 30%;
  }

  h4 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  p {
    font-size: 2.4rem;
    font-weight: 300;
  }

  @media(max-width: 1024px){
    height: auto;
    padding-bottom: 3.2rem;
    
    h4 {
      font-size: 2.4rem;
    }
  
    p {
      font-size: 1.8rem;
    }
  }

  @media(max-width: 440px){
    height: auto;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;

    h4 {
      font-size: 1.4rem;
    }
  
    p {
      font-size: 1.2rem;
    }
  }
`

export default function Details( props ) {
  return (
    <Container>
      <div className="role">
        <h4>Role</h4>
        <p>{props.role}</p>
      </div>
      <div className="stack">
        <h4>Stack</h4>
        {props.stack.map((elm, index) => 
          <p key={index}>{elm}</p>
        )}
      </div>
      <div className="focus">
        <h4>Focus</h4>
        {props.focus.map((elm, index) => 
          <p key={index}>{elm}</p>
        )}
      </div>
    </Container>
  )
}