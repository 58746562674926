import styled from 'styled-components'

const Layout = styled.main`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  box-sizing: border-box;
  grid-template-columns: [side-left] 159px [content-start] auto [content-end] 159px [side-right];
  grid-template-rows: auto;
  padding: 3.2rem;

  @media(max-width: 1024px){
    display: grid;
    grid-template-columns: [content-start] auto [content-end];
    grid-template-rows: auto;
    padding: 2.4rem;
  }
`

export default Layout