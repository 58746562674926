import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

const Container = styled.aside`
  position: fixed;
  height: 100vh;
  bottom: 0;
  padding: 3.2rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  font-family: 'Teko', sans-serif;
  z-index: -1;

  #label {
    font-size: 1.8rem;
    font-weight: 500;
  }

  #section {
    font-size: 1.4rem;
    font-weight: 300;
  }
  
  @media(max-width: 1024px){
    display: none;
  }
`

const Aside = (props, ref) => {
  const [label, setLabel] = useState("🤖")
  const [section, setSection] = useState("Welcome...")

  const labelElm = useRef(null)
  const sectionElm = useRef(null)

  useEffect(() => {
    if(props.label){
      setSection(props.section)
      setLabel(props.label)
    }
  }, [props.label, props.section])

  return (
    <Container>
      <Fade>
        <div>
          <p id='label' ref={labelElm}>{label}</p>
          <p id='section' ref={sectionElm}>{section}</p>
        </div>
      </Fade>
    </Container>
  )
}

export default Aside