import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useContextInfo } from '../hooks/context'
import sanityClient from '../client.js'
import BlockContent from '@sanity/block-content-to-react'
import Paragraph from '../components/Paragraph'
import Details from '../components/Details'
import Lead from '../components/Lead'
import Body from '../components/Body'
import End from '../components/ProjectEnd'
import Loading from '../components/Loading'
import Fade from 'react-reveal/Fade'
import SectionMarker from '../components/SectionMarker'

export default function Project() {
  const [project, setProject] = useState(null)
  const { slug } = useParams()

  const { setSideContent } = useContextInfo()

  const introRef = useRef(null)
  const leadRef = useRef(null)
  const solutionRef = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    
    setTimeout(() => {
      sanityClient
        .fetch(
          `*[slug.current == $slug][0]{
            company,
            focus,
            intro,
            previewImage{
              asset->{
                _id,
                url
              }
            },
            lead,
            role,
            site,
            slug,
            body,
            stack
          }`, { slug } 
        )
        .then((data) => setProject(data))
        .catch(console.error)
    }, 1200);
  }, [slug])

  useEffect(() => {
    if(introRef.current) {
      let result = {
        intro: {
          number: project.company,
          top: 0,
          bottom: introRef.current.getBoundingClientRect().bottom,
          text: 'Intro'
        },
        lead: {
          number: project.company,
          top: leadRef.current.getBoundingClientRect().top,
          bottom: leadRef.current.getBoundingClientRect().bottom,
          text: 'Lead'
        },
        body: {
          number: project.company,
          top: solutionRef.current.getBoundingClientRect().top,
          bottom: solutionRef.current.getBoundingClientRect().bottom,
          text: 'Body'
        }
      }

      setSideContent(result)
    }

    return () => {
      let result = {
        intro: {
          number: '🤖',
          top: 0,
          bottom: window.innerHeight,
          text: 'Loading...'
        }
      }

      setSideContent(result)
    }
  }, [project, setSideContent])

  return (
    <>
    {project ?
    <div>
      <div ref={introRef}>
        <Fade>
          <Paragraph>        
            <BlockContent
              blocks={project.intro}
              projectId={sanityClient.clientConfig.projectId}
              dataset={sanityClient.clientConfig.dataset}
            />
          </Paragraph>
          <Details
            role={project.role}
            stack={project.stack}
            focus={project.focus}
          />
        </Fade>
      </div>

      <SectionMarker
        label={project.company}
        section="Lead"
      />

      <div ref={leadRef}>
        <Fade>          
          <Lead
          content={project.lead}
          />
        </Fade>
      </div>

      <SectionMarker
        label={project.company}
        section="Body"
      />

      <div ref={solutionRef}>
        <Body>
          <BlockContent
            blocks={project.body}
            projectId={sanityClient.clientConfig.projectId}
            dataset={sanityClient.clientConfig.dataset}
          />
        </Body>
        <End/>
      </div>
    </div>
    :
    <Loading/>
    }
    </>
  )
}