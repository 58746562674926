import styled from 'styled-components'

const Paragraph = styled.section`
 display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  height: calc(100vh - 52px);
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  font-size: 3.6rem;
  text-align: left;
  margin-right: 15%;
  color: #333333;

  p {
    margin-bottom: 2.4rem;
  }

  a {
  font-size: 2.4rem;
  color: #34E49E;

    &:visited {
      color: #34E49E;
    }
  }

  @media(max-width: 1024px){
    font-size: 2.4rem;
    height: auto;
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;

    a {
      font-size: 1.4rem;
    }
  }

  @media(max-width: 440px){
    font-size: 1.8rem;
  }
`

export default Paragraph